#outlook a {
	padding: 0;
}

*,
body,
table,
td,
a {
	text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

#table-root,
#table-root * {
	background-color: #fff;
}

table,
td {
	mso-table-lspace: 0pt;
	mso-table-rspace: 0pt;
}

table,
td {
	mso-table-lspace: 0pt;
	mso-table-rspace: 0pt;
}

img {
	border: 0;
	height: auto;
	line-height: 100%;
	outline: none;
	text-decoration: none;
	-ms-interpolation-mode: bicubic;
}

p {
	display: block;
	margin: 13px 0;
}

hr {
	margin: 0.35em 0;
}
